















































import Vue from "vue";
import Component from "vue-class-component";
import PrimaryConsultants from "../../components/survey/PrimaryConsultants.vue";
import InPersonClient from "../../components/survey/InPersonClient.vue";
import { AxiosResponse } from "axios";
import { CallApi } from "@/networks/survey";
import { Survey } from "@/@types/survey";

@Component({
  components: {
    Consultants: PrimaryConsultants,
    InPersonClient
  }
})
export default class Survay extends Vue {
  private data: Survey | boolean = false;
  private hasParticipated: any = "";

  mounted() {
    this.callApi();
  }

  public async callApi(): Promise<any> {
    this.data = false;
    const params = this.$route.params;
    const url = `/surveys/${params.srvId}/entrieable/${params.evntId}/user/${params.usreId}`;
    console.log(url);
    try {
      const res: AxiosResponse = await new CallApi().survey(url);
      console.log("res", res.data.data);
      this.data = res.data.data;
    } catch (e: any) {
      console.log("e", e, e.response.status);
      if (e.response.status === 406) {
        this.hasParticipated = e.response.data.message;
      } else {
        this.$router.push("/login");
      }
    }
  }
}
