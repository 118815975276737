























































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Survey } from "@/@types/survey";
import { CallApi } from "@/networks/survey";
import store from "@/store";

@Component
export default class PrimaryConsultants extends Vue {
  private tempId = 0;
  private questions = {} || undefined;
  private form: any = {};
  private descFile: any = {} || undefined;
  private qExtraDescription: any = "";
  private loader = false;

  @Prop({ default: false })
  data: boolean | Survey | undefined;

  mounted() {
    this.questions = this.data;
  }

  get moreDescription(): any {
    let temp = true;
    Object.keys(this.form).map((item) => {
      if (this.form[item] < 4) {
        temp = false;
        this.qExtraDescription = "";
      }
    });
    return temp;
  }

  public handleChangeRateDescrip(rate: number, id: any): any {
    if (this.form["q" + id] > 3) {
      let descFile = this.descFile;
      delete descFile["q" + id + "_extra_description"];
      delete descFile["q" + id + "_extra_file"];
      this.descFile = descFile;
    }
    console.log(rate, id);
  }

  public async submitForm(): Promise<void> {
    // extra_file  // extra_description
    let form = {};
    const formSubmit = new FormData();
    Object.keys(this.form).map((item) => {
      formSubmit.append(`${[item]}`, this.form[item]);
    });
    Object.keys(this.descFile).map((item) => {
      formSubmit.append(`${[item]}`, this.descFile[item]);
    });
    formSubmit.append("q_extra_description", this.qExtraDescription);

    for (var pair of formSubmit.entries()) {
      console.log(pair[0], pair[1]);
    }

    this.loader = true;
    const params = this.$route.params;
    try {
      await new CallApi().createSurvey(
        {
          surveyID: params.srvId,
          eventID: params.evntId,
          userID: params.usreId
        },
        formSubmit
      );
      store.commit("showError", {
        message: "نظر شما با موفقیت ثبت شد",
        color: "success"
      });
      this.loader = false;
      this.$emit("changeStatus");
    } catch (error: any) {
      store.commit("showError", {
        message: error.message,
        color: "danger"
      });
      this.loader = false;
    }
    console.log(form, formSubmit);
  }
}
