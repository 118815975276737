








































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Survey } from "@/@types/survey";
import { CallApi } from "@/networks/survey";
import { slice } from "lodash";
import { ContractApi } from "@/networks/ContractApi";
import { promises } from "dns";
import store from "@/store";

@Component
export default class PrimaryConsultants extends Vue {
  private tempId = 0;
  private questions = {} || undefined;
  private form: any = {};
  private checkBoxs: Array<any> = [];
  private rateRadio: any = {} || undefined;
  private currentCheckboxChild = [];
  private checkBoxChilddRate: any = {} || undefined;
  private checkBoxChildDescrip: any = {} || undefined;
  private qExtraDescription: any = "";
  private loader = false;

  @Prop({ default: false })
  data: boolean | Survey | undefined;

  mounted() {
    this.questions = this.data;
  }

  get moreDescription(): any {
    const RateForm = this.calcRateForm();
    // const radioRate: any = Object.keys(this.rateRadio);
    return RateForm.rateStatus;
  }

  public handleChangeRateRadio(rateNumber: number, questioId: any): any {
    if (
      this.rateRadio["q" + questioId] > 3 &&
      this.rateRadio["q" + questioId + "_extra"]
    ) {
      this.rateRadio = { ["q" + questioId]: this.rateRadio["q" + questioId] };
    }
  }

  public handleChangeRateDescrip(
    rateNumber: number,
    sub: number,
    questioId: any
  ): any {
    console.log("here!", rateNumber, sub, questioId);
    if (rateNumber > 3) {
      let checkBoxChildDescrip = this.checkBoxChildDescrip;
      Object.keys(this.checkBoxChildDescrip).map((item) => {
        parseInt(item.slice(item.indexOf("_") + 1, item.length)) === questioId
          ? delete checkBoxChildDescrip[item]
          : {};
      });
      this.checkBoxChildDescrip = checkBoxChildDescrip;
    }
  }

  public getValue(str: string, i: number) {
    let value: any = str
      .slice(3, str.length)
      .split(",")
      .map((num) => parseInt(num));
    return value[i];
  }

  public checkboxRate(e: any, item: any) {
    console.log(e, item);
  }

  public subQeustionValues(question: any, i: number) {
    const subQuestions = question.child_questions.find(
      (q: any) => q.option_title === question.options[i]
    );
    return subQuestions.childs;
  }

  public showQuestionRelatedChecks(
    question: any,
    sub: any,
    questionId: number
  ) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const questionIndex = this.questions.findIndex(
      (item: any) => item.id === questionId
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const child_questions = this.questions[questionIndex].child_questions.find(
      (item: any) => item.option_title === question
    );
    this.currentCheckboxChild = child_questions;
    let checks: Array<any> = [];
    let isChoose = true;
    this.checkBoxs.map((item) =>
      item === sub ? (isChoose = false) : checks.push(item)
    );
    if (isChoose) {
      checks.push(sub);
    }
    console.log("checked : ", question, sub, questionId, checks.includes(sub));
    if (!checks.includes(sub)) {
      let checkBoxChilddRate = this.checkBoxChilddRate;
      Object.keys(this.checkBoxChilddRate).map((item) => {
        parseInt(item.slice(0, item.indexOf("_") + 1)) === sub
          ? delete checkBoxChilddRate[item]
          : {};
      });
      this.checkBoxChilddRate = checkBoxChilddRate;
      let checkBoxChildDescrip = this.checkBoxChildDescrip;
      Object.keys(this.checkBoxChildDescrip).map((item) => {
        parseInt(item.slice(0, item.indexOf("_") + 1)) === sub
          ? delete checkBoxChildDescrip[item]
          : {};
      });
      this.checkBoxChildDescrip = checkBoxChildDescrip;
    }
    this.checkBoxs = checks;
  }

  public showQuestionRelated(question: any, sub: any): void {
    this.form = {};
    this.rateRadio = {};
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.form["q" + question.id] = sub;
    console.log(sub);
    this.tempId = sub;
  }

  public calcRateForm(): any {
    const rateForm: any = [];
    let rateStatus = true;
    if (Object.keys(this.checkBoxChilddRate).length === 0) {
      return { rateForm: rateForm, rateStatus: true };
    }
    Object.keys(this.checkBoxChilddRate).map((item) => {
      item.indexOf("_");
      const qKey = parseInt(item.slice(0, item.indexOf("_")));
      const qValue = parseInt(item.slice(item.indexOf("_") + 1, item.length));
      if (this.checkBoxs.includes(qKey)) {
        rateForm.push([qValue, this.checkBoxChilddRate[item]]);
        if (this.checkBoxChilddRate[item] < 4) {
          rateStatus = false;
        }
      }
    });
    return { rateForm: rateForm, rateStatus: rateStatus };
  }

  public uploadFile(file: any, id: any) {
    this.form = { ...this.form, [id]: file.target.files[0] };
    console.log(file.target.files[0]);
  }

  public async submitForm(): Promise<void> {
    let form = this.form;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    form["q" + this.questions[1].id] = this.checkBoxs || {};
    form = {
      ...form,
      ...this.rateRadio,
      q_extra_description: this.qExtraDescription
    };
    Object.keys(this.checkBoxChilddRate).map((item) => {
      if (item.indexOf("_") > 0) {
        let temp = "q" + item.slice(item.indexOf("_") + 1, item.length + 1);
        form = { ...form, [temp]: this.checkBoxChilddRate[item] };
      } else {
        form = { ...form, [item]: this.checkBoxChilddRate[item] };
      }
    });
    Object.keys(this.checkBoxChildDescrip).map((item) => {
      let temp = "q" + item.slice(item.indexOf("_") + 1, item.length + 1);
      form = { ...form, [temp]: this.checkBoxChildDescrip[item] };
    });
    // submit and form data set
    const formSubmit = new FormData();
    Object.keys(form).map((item) => {
      if (Array.isArray(form[item])) {
        formSubmit.append(`${[item]}`, JSON.stringify(form[item]));
      } else {
        formSubmit.append(`${[item]}`, form[item]);
      }
    });

    for (var pair of formSubmit.entries()) {
      console.log(pair[0], pair[1]);
    }

    this.loader = true;
    const params = this.$route.params;
    try {
      await new CallApi().createSurvey(
        {
          surveyID: params.srvId,
          eventID: params.evntId,
          userID: params.usreId
        },
        formSubmit
      );
      store.commit("showError", {
        message: "نظر شما با موفقیت ثبت شد",
        color: "success"
      });
      this.loader = false;
      this.$emit("changeStatus");
    } catch (error: any) {
      store.commit("showError", {
        message: error.message,
        color: "danger"
      });
      this.loader = false;
    }
    console.log(form, formSubmit);
  }
}
