import { AxiosError, AxiosResponse } from "axios";
import Api from "@/networks/api";
import { Survey, SurveyRegistered } from "@/@types/survey";

export class CallApi extends Api {
  private token = "";
  constructor() {
    super();
    this.token = localStorage.getItem("token") || "";
  }

  public survey(url: string): Promise<AxiosResponse> {
    return this.get<Survey | SurveyRegistered>(url, {
      headers: { Authorization: "" }
    });
  }

  public createSurvey(params: any, data: any) {
    // /surveys/21/event/2001/user/1
    return this.post<ServerResponse>(
      `/surveys/${params.surveyID}/entrieable/${params.eventID}/user/${params.userID}`,
      data,
      {
        headers: {
          Authorization: "Bearer " + this.token
        }
      }
    );
  }
}
